<template>
  <div>
    <ul class="flex text-primary">
      <li class="w-4/12 text-center">Contact</li>
      <li class="w-4/12 text-center">Date Sent</li>
      <li class="w-4/12 text-center">Select</li>
    </ul>
    <div v-for="(data, i) in contactsWaitingForResponse" :key="i">
      <ul
        class="px-2 md:px-0 w-full my-3 py-4 border-t flex items-center font-proximaLight"
      >
        <li class="w-4/12 md:w-5/12 flex flex-col md:flex-row items-center">
          <div class="md:mr-2">
            <img
              v-if="data.profile_picture"
              class="h-6 w-6 rounded-full"
              :src="data.profile_picture"
              alt="pp-lg"
            />
            <img
              v-else
              class="h-6"
              src="@/assets/images/profilepic-icon.png"
              alt="pp-lg"
            />
          </div>
          <div class="text-center">
            {{ data.contactEmailAddress || data.contactphoneNo }}
          </div>
        </li>
        <li class="w-4/12 flex">
          <!--  {{ data.contactEmailAddress || data.contactphoneNo }} -->
          <div class="mx-2 flex items-center flex-wrap">
            {{ getDate(data.date_created) }}
          </div>
        </li>

        <li class="flex w-2/12 justify-center">
          <!--  <div>
            <div v-if="!checkedPet" class="border-2 border-primary rounded-md bg-white w-5 h-5"></div>
            <img v-else class="w-5 h-5" src="@/assets/images/check-icon.png" alt="check-icon" />
         </div> -->

          <input
            class="mr-2 border-2 border-primary rounded-md bg-white w-5 h-5"
            style="border-color: #3490dc"
            type="checkbox"
            :value="{ id: i + 1, name: data.name }"
            @change="checkDeleteContact(data)"
          />

          <!-- <RadioOnoffBorder
              :val="data"
              :checked="data.pets_shared"
              @inputVal="inputVal"
            /> -->
        </li>

        <!-- v-if="deleteConfirmation" -->
        <li class="flex w-2/12 md:w-1/12 md:hidden justify-end">
          <label class="checkbox-container">
            <input
              type="checkbox"
              :value="{ id: i + 1, name: data.name }"
              @change="checkDeleteContact(data)"
            />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
    </div>

    <ModalsConfirm :show="showDeleteModalsComp" @close="closeModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeModals"
        >
          ✖
        </div>
        <h1 class="mt-4 text-center text-2xl">
          Delete "{{ deleteName }}" from friends?
        </h1>
      </div>
      <div
        slot="actionText"
        class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <p>
          <span class="text-red">Attention:</span> Clicking confirm will remove
          this user from your friend list.
        </p>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <BorderedButton
          class="mx-auto"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-4"
          width="w-full"
          @click.native="closeModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <SubmitButton
          class="mx-auto"
          marginT="mt-2"
          title="Confirm"
          background="bg-red"
          paddingX="px-4"
          width="w-full"
          @click.native="submitDelete"
        />
      </div>
    </ModalsConfirm>
    <ModalsConfirm :show="showMsgModalsComp" @close="closeMsgModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeMsgModals"
        >
          ✖
        </div>
        <h1 class="mt-4 mb-5 text-2xl">Friend Request Note</h1>
      </div>
      <div slot="actionText" class="mb-6 flex flex-col text-xl">
        <h1>{{ msgNote }}</h1>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative"></div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative"></div>
    </ModalsConfirm>
  </div>
</template>
  
  <script>
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import ContactPetPictures from "./ContactPetPictures.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import RoutinePetsImage from "../routineoverview/RoutinePetsImage.vue";
import { mapActions, mapGetters } from "vuex";

import TrashIcon from "../icons/TrashIcon.vue";
import MessagIcon from "../icons/MessagIcon.vue";
import moment from "moment";

export default {
  props: ["contactsWaitingForResponse", "deleteConfirmation"],
  components: {
    RadioOnoffBorder,
    BorderedButton,
    SubmitButton,
    MessagIcon,
    TrashIcon,
    ContactPetPictures,
    RoutinePetsImage,
    ModalsConfirm,
  },
  data() {
    return {
      deleteName: "",
      msgNote: "",
      deleteData: {},
      check: false,
      deleteContactList: [],
    };
  },
  computed: {
    showDeleteModalsComp() {
      return this.$store.state.showDeleteModalsState;
    },
    showMsgModalsComp() {
      return this.$store.state.showMsgModalsState;
    },
  },
  methods: {
    ...mapActions({
      deleteContact: "contact/deleteContact",
      revokeSharepetsWithContact: "contact/revokeSharepetsWithContact",
      sharepetsWithContact: "contact/sharepetsWithContact",
      contactAcceptOrDecline: "contact/contactAcceptOrDecline",
    }),

    getDate(day) {
      return moment(String(day)).format("DD MMM, YYYY");
    },

    deleteFriend(e, i) {
      this.$store.commit("showDeleteModals", true);
      this.deleteName = e.name;
      this.deleteData = e;
    },
    checkDeleteContact(i) {
      console.log("deletListInvitation", i);
      const index = this.deleteContactList.findIndex((item) => item === i);
      if (index > -1) {
        // If the object exists, remove it
        this.deleteContactList.splice(index, 1);
      } else {
        // If the object does not exist, add it
        this.deleteContactList.push(i);
      }
      this.$emit("deletListInvitation", this.deleteContactList);
    },
    showMessage(e, i) {
      this.$store.commit("showMsgModals", true);
      this.msgNote = e.notes;
    },

    submitDelete() {
      this.deleteContact(this.deleteData);
    },

    submitContactAccept(data) {
      this.sharepetsWithContact(data);
      console.log(data);
      console.log("contact accept");
    },
    submitAccept(data) {
      console.log("accept");
      data.status = 2;
      this.contactAcceptOrDecline(data);
    },
    submitDecline(data) {
      data.status = 3;
      this.contactAcceptOrDecline(data);
    },

    closeModals() {
      this.$store.commit("showDeleteModals", false);
    },

    closeMsgModals() {
      this.$store.commit("showMsgModals", false);
    },

    async inputVal(val) {
      console.log(val, val.pets_shared);
      const check = !val.pets_shared;
      console.log(check);
      check
        ? this.sharepetsWithContact(val)
        : this.revokeSharepetsWithContact(val);
      /* this.toggleIncognito = !this.toggleIncognito;
        console.log(this.pet.petID, val, check);
        const data = {
          check: check,
          pet: this.pet,
        };
  
        this.updatePrivateMode(data); */
    },
  },
};
</script>
  
<style>
/* The container */
.container {
  display: block;
  font-family: "Milliard Medium";
  color: "#354E57";
  position: relative;
  padding-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
